/* ----------- VARIABLES ----------- */

@font-face {
    font-family: "Work Sans";
    src: url(WorkSans-VariableFont_wght.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(Poppins-Medium.ttf);
}
@font-face {
    font-family: "Caveat Brush";
    src: url(CaveatBrush-Regular.ttf);
}



/* --- media queries - only for usability, it's not gonna be pretty on big screens and that's ok --- */

@media screen and (min-width: 1000px) {
    .clueWord {
        div span {
            font-size: 50%;
        }
    }
    .instructionsBody {
        margin: 15rem 10rem;
        width: 50%;
        
    }
}


/* --- mixins and variables --- */
$white: #fff;
$black: #000;
$lightcream: #F6F4F4;
$cream: #E2DADB;
$darkerpurple: #201d1e;
$darkpurple: #3A3335;
$orange: #F0544F;
$pink: #D81E5B;
$blue: #58A1B2;

$green: #81B775;
$red: #DE5757;


$blue1: #73B0BF;
$blue2: #65A8B8;
$blue3: #58A1B2;
$blue4: #478A9A;
$blue5: #407D8C;
$blue6: #3A707E;

$pink1: #F1BBDC;
$pink2: #EA99CA;
$pink3: #E378BA;
$pink4: #DC56A9;


$green1: #71C191;
$green2: #55B47B;
$green3: #439966;
$green4: #32714B;


$dark1: #0B0A0A;
$dark2:#201D1D; 
$dark3: #363030;
$dark4: #413A3A;
$dark5: #4C4343;
$dark6: #564D4D;

$light1: #fff;
$light2: #F6F4F4;
$light3: #EDE9E9;
$light4: #E3DDDD;
$light5: #DAD2D2;
$light6: #D1C7C7;


@mixin zeromarg {
    padding: 0;
    margin: 0;
}

@mixin page-edge {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
@mixin padding-vert($val){
    padding-top: $val;
    padding-bottom: $val;
}


/* -------- TYPOGRAPHY --------- */

body {
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
}
h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1rem;
}
h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: .8rem;
}
h5 {
    // this is for center-aligned game state messages
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem;
}
dl {
    dt {

    }
    dd {

    }
}
@mixin ptext {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    font-size: .8rem;
}

a {
    color: $blue5;
}
/* -------- OFFSET STROKE --------- */


$halftone: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAHElEQVQYV2NUVFT8f//+fUYGBgYGMAEDcA5IBQCKJQVmn76DhAAAAABJRU5ErkJggg==");

@mixin stroke-width($property) {
	-webkit-text-stroke-width: $property;
	-moz-text-stroke-width: $property;
}

@mixin stroke-color($property) {
	-webkit-text-stroke-color: $property;
	-moz-text-stroke-color: $property;
}

@mixin background-clip($property) {
	-webkit-background-clip: $property;
	-moz-background-clip: $property;
	background-clip: $property;
}

@mixin fill-color($property) {
	-webkit-text-fill-color: $property;
	-moz-text-fill-color: $property;
}



$stroke: $dark1;
$shadow: #EA99CA;

.heading {
	margin: 0;

	&--stroke {
		@include stroke-width(1px);
		@include stroke-color($stroke);
		color: transparent;
	}

	&--shadow {
		text-shadow: -2px -2px $shadow;
	}

	&--stroke-shadow {
		text-shadow: -2px 0 $stroke, 0 -2px $stroke, 2px 0 $stroke, 0 2px $stroke,
			2px 2px $stroke, -2px -2px $stroke, -2px 2px $stroke, 2px -2px $stroke,
			6px 6px $shadow;
	}

	&--halftone {
		position: relative;

		&:after {
			background: $halftone repeat;
			@include stroke-width(0);
			@include background-clip(text);
			@include fill-color(transparent);
			
			letter-spacing: 6px;
			left: calc(50% + 6px);
			top: calc(50% + 6px);
			content: "Hello";
			z-index: -1;
			position: absolute;
			transform: translate(-50%, -50%);
		}

		&-color {
			&:after {
				background-color: $shadow;
			}
		}
	}
}

/* ----------- BASE STYLES ----------- */

body {
    @include zeromarg();
    .root {
        @include zeromarg();
    }
    background-color: $light3;
}

button {
    font-size: 1.1rem;
    border-radius: 3px;
    padding: .6rem 1.2rem;
    border: none;
    font-weight: 700;
    cursor: pointer;
}
@mixin buttonlike {
    font-size: 1.1rem;
    border-radius: 3px;
    padding: .6rem 1.2rem;
    border: none;
    font-weight: 700;
    cursor: pointer;
}

@mixin greenbutton {
    background-color: $green3;
    color: $light1;
}
@mixin pinkbutton {
    background-color: $pink3;
    color: $light1;
}

@mixin yesno {
    border-radius: 3px;
    padding: .2rem .5rem;
    text-align: left;
    margin: 1rem 0;
    ul {
        list-style-type: none;
        padding: .7rem 1.3rem;
        margin: .2rem .2rem .2rem 1rem;
        li {
            padding-bottom: .5rem;
            span.example {
                font-style: italic;
            }
        }
    }
}

.yes {
    @include yesno();
    background-color: rgba(67, 153, 102, .3);
    
    ul li:before {    
        font-family: 'FontAwesome';
        content: '\f164';
        margin:0 5px 0 -25px;
        color: $green3;
    }
}
.no {
    @include yesno();
    background-color: rgba(11, 10, 10, .3);
    ul li:before {    
        font-family: 'FontAwesome';
        content: '\f165';
        margin:0 5px 0 -25px;
        color: $dark2;
    }
}

.hint {
    @include yesno();
    background-color: rgba(220,86,169,.3);
    ul li:before {    
        font-family: 'FontAwesome';
        content: '\f0a4';
        margin:0 5px 0 -25px;
        color: $pink4;
    }
}




/* ------------ NAV -------------- */

.gameTitle {
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'Work Sans', sans-serif;
    
    font-size: 2.7rem;
    @include padding-vert(.2rem);
    margin: 0;
    a {
        text-decoration: none;
        color: transparent;
    }
}

.gameNav {
    display: flex;
    justify-content: space-between;
    background-color: $blue3;
   border-bottom: 3px solid $blue4;
    
    @include page-edge();
    padding-right: 0;
    padding-bottom: 0;
    margin: 0;
   
          align-items: center;


    
        
    }
    .gameName {
        text-transform: uppercase;
        font-weight: 400;
        font-family: 'Work Sans', sans-serif;
        color: $white;
        letter-spacing: .04rem;
       
        
        padding: 1rem 1rem 1rem 1rem;
        margin: 0;
        cursor:pointer;
    }
    .gameSettings {
        color: $white;
        display: inline-block;
        padding-left: 10px;
        
    }
    .gameMenu {
        @include zeromarg();
        letter-spacing: normal;
        position: relative;
        text-align: right;
        top: 0;
        left: 0;
        
        ul {
            list-style-type: none;
            @include zeromarg();
            margin: .4rem 0 0 0;
            li {
                font-weight: 400;
                font-size: .9rem;
                margin: .4rem 0;
                a{
                    color: $white;
                    text-decoration: none;
                    text-transform: none;
                }
            }
        }
    
}

  
  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 10px;
  }
  
  
  
  .square:focus {
    outline: none;
  }
  
  
  
  .game {
    display: flex;
    flex-direction: row;
  }
  
  .game-info {
    margin-left: 20px;
  }

 /* ---------- MODAL ----------- */
.ReactModalPortal {
    .closeX {
        
        font-size: 2rem;
        color: $dark4;
        position:fixed;
       z-index: 100;
        top: 3rem; 
        right: 4rem;
    }
    .modalBody {
        
    }
    .modalEnd {
        position: absolute;
        
    }
    

}

 /* ---------- TITLE ----------- */

 .gameLaunch {
     
     margin: 0;
     background-color: $blue3;
     font-family: "Work Sans", sans-serif;
     color: $white;
     text-align: center;
     .gameMainTitle {
        @include page-edge();
        padding-top: 1em;
       
         h1 {
            text-transform: uppercase;
            font-size: 4.5rem;
            margin: 0;
         }
        h3 {
            font-weight: 500;
            font-size: 1.6rem;
        }
        
        
     }
     button {
         background-color: $green2;
         color: $white;
         font-weight: bold;
     }
     .launchOptions {
        @include page-edge();
        background-color: $blue4;
        padding-top: .1rem;
        padding-bottom: 1rem;
         .joinGame {
           
             input {
                 
                 padding: .2rem .2rem;
                 font-size: 1rem;
                 width: 9rem;
                 height: 2rem;
                 border-radius: 3px;
                 border: 0;
             }
             label {
                 display: block;
             }

         }
         p {

         }
     }
 }
 .launchFooter {
     padding: .2rem 0 .5rem 0;
     margin: 0;
     a {
         color: $light2;
     }
     p {
        font-size: 1.1rem;
     }
     p.credits {
         font-size: .9rem;
     }
 }

 
  
/* -------- INSTRUCTIONS --------- */
.gameInstructions {
    p {
        @include ptext();
    }
}

 /* ---------- ADD TEAMS ----------- */

 .addTeams {
     @include page-edge();

     margin: 0;
     .addTeamForm {
         display: flex;
     }
     input {
         height: 2.4rem;
         flex: 2;
         font-size: .8rem;
         margin: 0 0 0 0;
         border: 0;
         border-radius: 3px 0 0 3px;
     }
     button {
         @include buttonlike();
         border-radius: 0 3px 3px 0;
         margin: 0;
         height: 2.5rem;
         background-color: $pink3;
         color: $light1;
     }
     .hostAddTeams {
         &:first-child {
             margin-top: 2.5rem;
         }
         
         border-bottom: none;
         
         
         margin: .5rem 0;
     }
     
 }


 .teamsDoneButton {
     text-align: center;
     margin-top: 2rem;
     margin-bottom: 1.5rem;
     button {

         @include pinkbutton();
     }
 }

 

 /* ---------- ADD WORDS ----------- */
 .addWords {
     @include page-edge();
     text-align: center;
     margin-top: 1rem;
     margin-bottom: 1rem;
     

     .bowlImage{
         left: 0;
     }

     .addWord {
        font-family: "Caveat Brush", sans-serif;
        background-color: $white;
        width: 90%;
        text-align: center;
        font-size: 2.5rem;
        margin: 1rem 0;
        padding: .1rem .5rem .5rem .5rem;
        height: 3rem;
        line-height: 1rem;
        align-items: center;
        justify-content: center;
        border: 0px;
        border-radius: 3px;
        .scaletext-wrapper {
            overflow: visible !important;
            span {
                position: relative !important;
                top: .5rem !important;
            }
        }
        
      }
      .submitWord {
          display: block;
        @include buttonlike();
        background-color: $green2;
        color: $light1;
        cursor: pointer;
        margin: 0 auto;

      }


 }

  /* ---------- HOST START GAME ----------- */

  .hostStartGameDialog {
      
      .hostStartGame {
        @include page-edge();
        margin: 0 1rem;
        padding-top: .1rem;
        padding-bottom: .1rem; 
        background-color: $light1;
        border-radius: 3px;

      }
      .startGameButton{
          text-align: center;
          button {
            @include pinkbutton();
          }

      }
  }

  /* ---------- ALL TEAMS LIST ----------- */

  .teamRow {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: $black;
      @include page-edge();
      @include padding-vert(.2rem);
      background-color: $light5;
      border-bottom: 3px solid $light6;
      font-family: 'Work Sans', sans-serif;
      margin: 0;
      .teamDetails {
          padding: .5rem 0;
        flex: 2;
        
        align-items: center;

        
        button {
            @include greenbutton();
            margin: .7rem 0 0 0;
            font-size: 1.1rem;
        }
        p {
            @include zeromarg();
        }
        .countdown {
            font-size: 2rem;
            font-weight: 300;
            padding: .3rem .6rem;
            margin: .8rem 0;
            background-color: $pink4;
            border-radius: 3px;
            width: 7rem;
            font-size: 2.5rem;
            text-align: right;

        }

        .teamName {
            @include zeromarg();
            font-size: 1.3rem;
          font-weight: 500;
          
          
        }
      }
      
      &.active {
          background-color: $pink3;
          color: $white;
          border-bottom: 3px solid $pink4;
          .teamName {

            
            
          }
      }
      .teamScore {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: .8rem 1.2rem;
          margin: .4rem 0 .4rem 1rem;
          background-color: $white;
          color: $black;
          text-align: right;
          font-weight: 700;
          font-size: 1.5rem;
          width: 1rem;
          height: 1.5rem;
          .scoreNumber {
              @include zeromarg();
          }
      }

  }



  /* ---------- CLUEING ----------- */

  .clueWidget {
     

      .clueWord {
        @include page-edge();
        font-family: 'Caveat Brush', sans-serif;
        //background-color: $white;
        text-align: center;
        font-size: 3rem;
        
        margin: 0;
        padding: 2rem .5rem 2rem .5rem;
        position: relative;
        top: -1rem;
        line-height: 1.9em;
        align-items: center;
        justify-content: center;
        .scaletext-wrapper {
            overflow: visible !important;
            span {
                position: relative !important;
                top: 1.5rem !important;
            }
        }
        
      }

      .cluemeisterButtons {
          @include page-edge();
          height: 4rem;
          
      
      p {
          
          text-align: center;
          z-index: 500;
          justify-content: space-around;
          button {
              
              
              width: 100%;
              
              cursor: pointer;
          }

      }
    }
      .buttonCorrect {
        flex: 2;
        margin-bottom: 3px;

          button {
            background-color: $green3;
            font-size: 2rem;
            height: 5rem;
            
            
            border-bottom: 3px solid $green4;
            color: $light1;
               

          }
      }
      .otherButtons {
        display: flex;
        margin-top: 0;

        button  {
            margin: 0;
            font-size: 1rem;
            
            background-color: transparent;
            color: $light1;
            a {
                color: $light1;
            }

          }

      }
      .buttonWhoops, .buttonGiveUp {
        width: 100%;
        background-color: $light6 - #222;
        margin: 0;
        padding-top: .7rem;
        padding-bottom: .7rem;
        border-bottom: 3px solid $light6 - #444;
        border-radius: 3px;
        
          
      }
      .buttonGiveUp {
          margin-right: 3px;
      }
      

  }

   /* ---------- RECENTLY GUESSED ----------- */

   .recentlyGuessed {
       @include page-edge();
       padding: 3rem 1.5rem 1rem 1.5rem;
       p {
        text-align: center;
       }
       p.guessedWord {
           
           margin: 0;
           font-family: "Caveat Brush", sans-serif;
           font-size: 2.5rem;
          
            
            word-wrap: break-word;
          
       }
       
   }

  /* ---------- BOWL and GAMEDASH ----------- */

  .gameDash {
      display: flex;
      background-color: $light5;
      
      position: fixed;
      bottom: 0;
      width: 100%;
      justify-content: space-around;
      .roundRule, .bowl {
          max-width: 50%;
          padding: 1.2rem 1rem;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: .9em;
          img {
              height: 5rem;
          }
          
      }
      .roundRule {
          
      }
      .bowl {

      }

  }

  /* ---------- GAME END ----------- */
  .gameEnd {
      @include page-edge();
      .newGameButton {
          text-align: center;
          button {
            @include pinkbutton();
          }

          
      }
      .fa-heart {
        color: $pink4;
    }
      .werbung {
          text-align: center;
      }
  }

/* ----------- INSTRUCTIONS ----------- */
.gameInstructions {
    
    .instructionsNav {
        @include page-edge();
        background-color: $blue3;
        margin-top: 0;
        position: fixed;
        top: 0;
        width: 100%;
        height: 4rem;
        
        
        
        
        
        div.languages {
            position: absolute;
            right: 4rem;
            top: .5rem;
            ul {
                list-style-type: none;
                display: flex;
                padding-left: 0;
                margin-left: 0;
    
                li {
                    justify-content: space-between;
                    padding: 0 .5rem;
                    a {
                        text-decoration: none;
                        color: $light2;
                    }
                }
            }
        }

        

    }
    .instructionsBody {
        @include page-edge();
        font-family: "Work Sans", sans-serif;
        margin-top: 5rem;
        h3 {
            font-family: "Poppins", sans-serif;
            font-size: 1.4rem;
        }
        h4 {
            font-family: "Poppins", sans-serif;
            font-size: 1.1rem;

        }
        img {
            max-height: 8rem;
        }
        .goButton button {
            @include greenbutton();
        }
        .pink {
            color: $pink4;
        }
        
    }
}
// both for instruction page and for modals
.roundDescriptions, .newRoundDescription {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
        .roundTitle {
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-weight: 700;
            font-size: 1rem;
        }
        .guessExample {
            background-color: $white;
            padding: .1rem 1rem;
            text-align: center;
            .guessExampleWord {
                font-family: "Caveat Brush", sans-serif;
                font-size: 1.6em;
            }
            .guessExampleText {

            }
        }
    
}
// just for modals

.ReactModal__Content--after-open {
    padding: 0 !important;
}

.newRoundModal {
    
    height: 100%;
    background-color: $light3;
    border: 1px solid $light5;

    .guessExample {
        margin: 0rem 0;
    }
    
    .startRoundButton {
        text-align: center;
        margin: .5rem 0;
        button {
            @include greenbutton();
            
            
        }
        

    }
}
.hostModal.newRoundModal {
    .startRoundButton {
        button {
            @include pinkbutton();
        }
    }
    
}

.newRoundDescription {
    padding: .5rem 1.5rem;
    
    img {
        max-height: 8rem;
    }
}


// .bowl {
//     @include page-edge();
//     @include padding-vert(.3rem);
//     .remainingWords {
//         @include zeromarg();
//         font-family: "Work Sans", sans-serif;
//         font-size: 5rem;
//         color: $white;
//         font-weight: 800;
//         display: flex;
//         width: 100%;
//         justify-content: center;
//         align-items: center;
//         position: relative;
//         top: .35rem;
//     }
//     .remainingWordsSVG {
//         font-family: "Work Sans", sans-serif;
//         font-size: 96px;
//         font-weight: 800;
//         text-anchor: middle;
//         position: absolute;
//         bottom: 20px;
//         left: 0;
//         width: 100%;
        
//         .textFill {
//             fill: $white;
//             stroke: none;
            
//         }
//         .textStroke {
//             stroke: #000;
//             fill: none;
//             stroke-width: 2;
//         }
//     }
    
    
//     .bowlImage {
//         @include zeromarg();
//         position: relative;
//         z-index: -1;
//         bottom: -2rem;
//         img {
//             height: 8rem;
//             width: 100%;
//             margin-left: auto;
//         margin-right: auto;
        
//         }

//     }

// }
